var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title"},[_c('label',[_vm._v("Request "+_vm._s(_vm.ClientCode))])]),_c('div',{staticClass:"page-details row"},[(_vm.IsDataLoaded&&!_vm.IsApproveOrRejectEnabled)?_c('div',{staticClass:"text-center text-danger col-lg-12"},[_vm._v("The Request is already approved or rejected.")]):_vm._e(),_c('client-overview',{staticClass:"col-lg-9",attrs:{"ClientRequest":_vm.ClientRequest,"ID":_vm.id}}),_c('div',{staticClass:"col-lg-3 approval-side-section"},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"approve-reject-label"},[_vm._v("Approve / Reject Client Request")]),_c('p',{staticClass:"approve-reject-content"},[_vm._v(" Approve this request to send to the next approver. ")]),_c('div',{staticClass:"text-right",staticStyle:{"width":"100%"}},[(_vm.IsApproveOrRejectEnabled)?_c('button',{staticClass:"btn custom-button btn-primary",on:{"click":function($event){return _vm.Approve()}}},[_vm._v(" Approve ")]):_c('button',{staticClass:"btn custom-button btn-primary",attrs:{"disabled":"disabled","title":"The request is already approved or rejected."}},[_vm._v(" Approve ")])])]),_c('div',{staticClass:"row reject-section"},[_c('label',{staticClass:"rejection-label"},[_vm._v("Rejection Comments")]),_c('b-textarea',{staticClass:"rejection-comments",class:{
                errorborder: !_vm.$v.Comments.required && _vm.$v.Comments.$dirty,
                noterrorborder: _vm.$v.Comments.required,
              },attrs:{"placeholder":"Enter Rejection Comments","rows":"5"},on:{"blur":function($event){return _vm.SetDirty()},"focus":function($event){return _vm.ResetDirty()}},model:{value:(_vm.Comments),callback:function ($$v) {_vm.Comments=$$v},expression:"Comments"}}),(!_vm.$v.Comments.required && _vm.$v.Comments.$dirty)?_c('div',{staticClass:"error-message-format required-field"},[_vm._v(" Required field ")]):_vm._e(),_c('div',{staticClass:"text-right",staticStyle:{"width":"100%","padding-top":"10px"}},[(_vm.IsApproveOrRejectEnabled)?_c('button',{staticClass:"btn custom-button btn-outline-primary",on:{"click":function($event){return _vm.Reject()}}},[_vm._v(" Reject ")]):_c('button',{staticClass:"btn custom-button btn-outline-primary",attrs:{"disabled":"disabled","title":"The request is already approved or rejected."}},[_vm._v(" Reject ")])])],1)])],1),(!_vm.Error&&!_vm.HideFooterButtons)?_c('b-form-group',{staticClass:"text-right footer-buttons"},[_c('button',{staticClass:"btn custom-button btn-link",on:{"click":function($event){return _vm.Cancel()}}},[_vm._v(" Cancel ")]),(_vm.IsApproveOrRejectEnabled)?_c('button',{staticClass:"btn custom-button btn-outline-primary",on:{"click":function($event){return _vm.ShowReject()}}},[_vm._v(" Reject ")]):_c('button',{staticClass:"btn custom-button btn-outline-primary",attrs:{"disabled":"disabled","title":"The request is already approved or rejected."}},[_vm._v(" Reject ")]),(_vm.IsApproveOrRejectEnabled)?_c('button',{staticClass:"btn custom-button btn-primary",on:{"click":function($event){return _vm.Approve()}}},[_vm._v(" Approve ")]):_c('button',{staticClass:"btn custom-button btn-primary",attrs:{"disabled":"disabled","title":"The request is already approved or rejected."}},[_vm._v(" Approve ")])]):_vm._e(),_c('b-modal',{attrs:{"no-close-on-backdrop":true,"scrollable":"","title":"Add Product"},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
              var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.ModalTitle))]),_c('button',{staticClass:"close",on:{"click":function($event){return _vm.CancelReject()}}})]}},{key:"modal-footer",fn:function(ref){
              var ok = ref.ok;
              var cancel = ref.cancel;
return [_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-link text-uppercase",on:{"click":function($event){return _vm.CancelAdd()}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"text-uppercase btn custom-button btn-primary",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.Reject()}}},[_vm._v(" Reject ")])])]}}]),model:{value:(_vm.ShowRejectModal),callback:function ($$v) {_vm.ShowRejectModal=$$v},expression:"ShowRejectModal"}},[_c('div',{staticClass:"d-block row col-lg-6 col-md-8 offset-lg-2 offset-lg-3 primary-content"},[_c('b-row',[_c('b-col',[_c('label',[_vm._v("Rejection Comments")])])],1),_c('b-row',[_c('b-col',[_c('b-textarea',{attrs:{"rows":"5"},model:{value:(_vm.Comments),callback:function ($$v) {_vm.Comments=$$v},expression:"Comments"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }